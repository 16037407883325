import Repository, {baseUrl, socketBase} from './Repository';


export async function getPropertyList(key, lang) {
    return await Repository.get(`${baseUrl}/properties-ms/property/parent-key/${key}?lang=${lang}`)
        .then(response => {
            if (response.data) {
                if (response.data.code === 200) {
                    return response.data.body;
                } else {
                    return [];
                }
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}

export async function getPageData(key, language) {
    if (language) {
        key = key + "." + language;
    }
    return await Repository.get(`${baseUrl}/properties-ms/property/key/${key}`)
        .then(response => {
            if (response.data) {
                if (response.data.code === 200) {
                    return response.data.body;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}

export async function getLocationList() {
    return await Repository.get(`${baseUrl}/properties-ms/property/location`)
        .then(response => {
            if (response.data) {
                if (response.data.code === 200) {
                    return response.data.body;
                } else {
                    return [];
                }
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });
}


export async function getExpertList(token, fromLang, toLang, fromDate, toDate, fromCost, toCost, locationId, jobType, isSworn, selectedCategories) {
    console.log(selectedCategories);
    let endPoint = `${baseUrl}/user-jobs-ms/search?langFromKey=${fromLang}&langToKey=${toLang}&dateFrom=${fromDate}&dateTo=${toDate}&costFrom=${fromCost}&costTo=${toCost}&locationId=${locationId}&jobTypes=${jobType}&sworn=${isSworn > 0}`;
    if (selectedCategories) {
        selectedCategories.forEach(c => {
            endPoint += `&jobKnowledge=${c}`;
        })
    }
    return await Repository.get(`${endPoint}`)
        .then(response => {
            if (response.data) {
                if (response.data.code === 200) {
                    return response.data.body;
                } else if (response.data.code === 404) {
                    return [];
                }
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}

export async function getHomePageExperts() {
    let endPoint = `${baseUrl}/user-jobs-ms/search/index`;
    return await Repository.get(`${endPoint}`)
        .then(response => {
            if (response.data) {
                if (response.data.code === 200) {
                    return response.data.body;
                } else if (response.data.code === 404) {
                    return [];
                }
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}


export async function getExpertDetails(token, id) {
    return await Repository.get(`${baseUrl}/users-ms/users/info/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data) {
                if (response.data.code === 200) {
                    return response.data.body;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}


export async function getChatOpponentDetails(token, id) {
    return await Repository.get(`${baseUrl}/messaging-ms/api/v1/opponent/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data) {
                if (response.data.code === 200) {
                    return response.data.body;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}


export async function contactForm(formData) {
    return await Repository.post(`${baseUrl}/users-ms/contact`, formData)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}

export async function login(email, password) {
    return await Repository.post(`${baseUrl}/users-ms/login`, {login: email, password: password})
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}

export async function register(email, password, name, surname, type) {
    return await Repository.post(`${baseUrl}/users-ms/login/register`,
        {login: email, password: password, name: name, surname: surname, userType: type})
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}

export async function registerOtp(key, otp) {
    return await Repository.get(`${baseUrl}/users-ms/login/confirm/key/${key}/otp/${otp}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}

export async function forget(email) {
    return await Repository.post(`${baseUrl}/users-ms/login/forgot-password`,
        {login: email})
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}

export async function withGoogle(token, userType) {
    return await Repository.get(`${baseUrl}/users-ms/login/with-google?userType=${userType}&token=${token}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}

export async function withFacebook(token, userType) {
    return await Repository.get(`${baseUrl}/users-ms/login/with-facebook?userType=${userType}&token=${token}`)
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}

export async function getMe(token) {
    return await Repository.get(`${baseUrl}/users-ms/users`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            return {
                code: error.response.status,
                message: error.response.statusText,
            };
        });
}

export async function setStatus(token, status) {
    return await Repository.put(`${baseUrl}/users-ms/users/${status}`, null, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}

export async function setLanguage(token, language) {
    return await Repository.put(`${baseUrl}/users-ms/users/change-language?lang=${language}`, null, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}


export async function replyReview(token, rateId, answer) {
    return await Repository.put(`${baseUrl}//rate-ms/answer`, {
        "idRate": rateId,
        "answer": answer
    }, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}


export async function changePassword(token, password) {
    return await Repository.post(`${baseUrl}/users-ms/users/reset-password`, {password: password}, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}

export async function updateProfile(token, details) {
    return await Repository.post(`${baseUrl}/users-ms/users`, details, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}

export async function upload(file, token, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);

    return await Repository.post(`${baseUrl}/photo-ms/files/upload`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${token}`
        },
        onUploadProgress
    }).then(response => {
        return response;
    }).catch(error => {
        console.log(JSON.stringify(error));
        return {data: {code: 2, message: error.message}};
    });
}


export async function photoUpload(file, token, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);

    return await Repository.post(`${baseUrl}/photo-ms/images/upload`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${token}`
        },
        onUploadProgress
    }).then(response => {
        return response;
    }).catch(error => {
        console.log(JSON.stringify(error));
        return {data: {code: 2, message: error.message}};
    });
}


export async function getMyJobs(token, id) {
    return await Repository.get(`${baseUrl}/user-jobs-ms/user-jobs/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}


export async function createNewJob(token, details) {
    return await Repository.post(`${baseUrl}/user-jobs-ms/user-jobs`, details, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}

export async function updateMyJob(token, details) {
    return await Repository.put(`${baseUrl}/user-jobs-ms/user-jobs`, details, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}

export async function addJobTime(token, details) {
    return await Repository.post(`${baseUrl}/user-jobs-ms/user-jobs/addTime`, details, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}


export async function editJobTime(token, id, details) {
    return await Repository.post(`${baseUrl}/user-jobs-ms/user-jobs/jobTime/${id}`, details, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}

export async function deleteJobTime(token, id) {
    return await Repository.delete(`${baseUrl}/user-jobs-ms/user-jobs/jobTime/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}


export async function deleteMyJob(token, id) {
    return await Repository.delete(`${baseUrl}/user-jobs-ms/user-jobs/job/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}


export async function registerInChat(id) {
    return await Repository.get(`${socketBase}/registration/${id}`)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}


export async function startChat(token, id) {

    return await Repository.get(`${baseUrl}/messaging-ms/api/v1/start-messaging/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });

}

export async function getUnreadMessageCount(token) {

    return await Repository.get(`${baseUrl}/messaging-ms/api/v1/unread-messages-count`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {
                code: 500,
                message: error.message
            };
        });

}

export async function getChartList(token) {

    return await Repository.get(`${baseUrl}/messaging-ms/api/v1/history`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data.code === 200) {
                return response.data.body.messages;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });

}

export async function getChartMessages(token, id, lastMessageId) {

    return await Repository.get(`${baseUrl}/messaging-ms/api/v1/history-info/idHistory/${id}/idMessage/${lastMessageId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data.code === 200) {
                return response.data.body;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });

}

export async function readMessage(token, messageId) {

    return await Repository.put(`${baseUrl}/messaging-ms/api/v1/read/idMessage/${messageId}`, null, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data.code === 200) {
                return response.data.body;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });

}

export async function getExpertReviews(token, id) {

    return await Repository.get(`${baseUrl}/rate-ms/get-rates/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data.code === 200) {
                return response.data.body;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });

}

export async function getExpertKnowledge(token, id) {

    return await Repository.get(`${baseUrl}/user-jobs-ms/user-jobs/languages/user/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data.code === 200) {
                return response.data.body;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });

}

export async function getOrderList(token, page, pageSize) {

    return await Repository.get(`${baseUrl}/order-ms/order/all?pageNumber=${page}&pageSize=${pageSize}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data.code === 200) {
                return response.data.body.result;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });

}

export async function getOrderInvoice(token, id) {

    return await Repository.get(`${baseUrl}/order-ms/order/generate/invoice/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data.code === 200) {
                return response.data.body;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });

}


export async function getOrdersByUserId(token, id) {

    return await Repository.get(`${baseUrl}/order-ms/order/active-orders/user/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data.code === 200) {
                return response.data.body;
            } else {
                return [];
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return [];
        });

}


export async function createOrder(token, details) {
    return await Repository.post(`${baseUrl}/order-ms/order`, details, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}


export async function editOrder(token, details) {
    return await Repository.post(`${baseUrl}/order-ms/order/re-order`, details, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}

export async function rateOrder(token, details) {
    return await Repository.post(`${baseUrl}/rate-ms/create`, details, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}


export async function exportChat(token, opponentId, from, to) {
    return await Repository.get(`${baseUrl}/messaging-ms/api/v1/export?opponentId=${opponentId}&dateFrom=${from}T00:00:00&dateTo=${to}T23:59:59`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });
}


export async function getOrder(token, id) {

    return await Repository.get(`${baseUrl}/order-ms/order/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data.code === 200) {
                return response.data
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });

}

export async function confirmOrder(token, id, paymentMethod) {

    return await Repository.put(`${baseUrl}/order-ms/order/${id}/accept?defaultPaymentInstrument=${paymentMethod}`, null, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data.code === 200) {
                return response.data
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });

}

export async function payCash(token, id) {

    return await Repository.put(`${baseUrl}/order-ms/order/${id}/cash-payment`, null, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data.code === 200) {
                return response.data
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });

}

export async function getTwistoEncriptedData(token, id) {

    return await Repository.get(`${baseUrl}/order-ms/order/${id}/twisto`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            return response.data
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return {
                code: error.response.status,
                message: error.response.statusText,
            };
        });

}

export async function confirmTwistoInvoice(token, id, transactionId) {

    return await Repository.post(`${baseUrl}/order-ms/order/confirm-twisto`, {
        "transaction_id": transactionId,
        "orderId": id
    }, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data.code === 200) {
                return response.data
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });

}

export async function finishOrder(token, id) {

    return await Repository.put(`${baseUrl}/order-ms/order/${id}/finished`, null, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data.code === 200) {
                return response.data
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });

}

export async function cancelOrder(token, id) {

    return await Repository.put(`${baseUrl}/order-ms/order/${id}/cancel`, null, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data.code === 200) {
                return response.data
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });

}

export async function complaintOrder(token, id, message, isCancellation) {

    return await Repository.put(`${baseUrl}/order-ms/order/complaint`, {
        orderId: id,
        message: message,
        cancellation: isCancellation
    }, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data.code === 200) {
                return response.data
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });

}

export async function getExpertLanguages(token, jobType) {

    return await Repository.get(`${baseUrl}/user-jobs-ms/user-jobs/type/${jobType}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.data.code === 200) {
                return response.data
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(JSON.stringify(error));
            return null;
        });

}
