<template>

  <div class="bg-white">

    <top-navigation/>

  </div>

  <div class="container" style="margin: 100px auto;">


    <div class="row">


      <div class="col-lg-4 mb-5">


        <h1 class="text-dark fw-bold">{{ $t('authentication.registerTitle') }}</h1>
        <div class="text-primary fw-bold display-3">{{ $t('authentication.registrationLinguamat') }}</div>

        <div class="my-5" v-if="registrationStep ===1">

          <div class="card-body" v-if="googleRegisterCase">
            <form @submit.prevent="googleRegistrationSubmit">
              <div class="mb-4">
                <div class="btn-group btn-group-lg w-100 " style="overflow: hidden">
                  <button class="btn btn-lg rounded-pill rounded-end" :class="{
                                    'btn-outline-primary': userType!== 'USER',
                                    'btn-primary': userType=== 'USER',
                                }" @click.prevent="userType='USER'"> {{ $t('userType.user') }}
                  </button>
                  <button class="btn btn-lg  rounded-pill rounded-start" :class="{
                                    'btn-outline-primary': userType!== 'EXPERT',
                                    'btn-primary': userType=== 'EXPERT',
                                }" @click.prevent="userType='EXPERT'"> {{ $t('userType.expert') }}
                  </button>
                </div>
              </div>


              <div class="mb-4 form-check">
                <input class="form-check-input" type="checkbox" id="terms_google" required>
                <label class="form-check-label" for="terms_google">
                  <a target="_blank" href="/page/terms">
                    {{ $t('general.acceptTermsAndConditions') }}
                  </a>
                </label>
              </div>


              <div class="mb-4 ">
                <button class="btn btn-primary btn-lg btn-pill px-4">
                  {{ $t('authentication.complete') }}
                </button>
              </div>
            </form>

          </div>
          <div class="card-body" v-else-if="facebookRegisterCase">
            <form @submit.prevent="facebookRegistrationSubmit">
              <div class="mb-4">
                <div class="btn-group btn-group-lg w-100 " style="overflow: hidden">
                  <button class="btn btn-lg rounded-pill rounded-end" :class="{
                                    'btn-outline-primary': userType!== 'USER',
                                    'btn-primary': userType=== 'USER',
                                }" @click.prevent="userType='USER'"> {{ $t('userType.user') }}
                  </button>
                  <button class="btn btn-lg  rounded-pill rounded-start" :class="{
                                    'btn-outline-primary': userType!== 'EXPERT',
                                    'btn-primary': userType=== 'EXPERT',
                                }" @click.prevent="userType='EXPERT'"> {{ $t('userType.expert') }}
                  </button>
                </div>
              </div>


              <div class="mb-4 form-check">
                <input class="form-check-input" type="checkbox" id="terms_fb" required>
                <label class="form-check-label" for="terms_fb">
                  <a target="_blank" href="/page/terms">
                    {{ $t('general.acceptTermsAndConditions') }}
                  </a>
                </label>
              </div>


              <div class="mb-4 ">
                <button class="btn btn-primary btn-lg btn-pill px-4">
                  {{ $t('authentication.complete') }}
                </button>
              </div>
            </form>

          </div>

          <div v-else>
            <div class="d-grid gap-4 mb-4">
              <button class="btn btn-lg btn-block btn-pill btn-outline-primary btn-social" type="button"
                      @click.prevent="googleAuth">
                <i class="fa fa-google" aria-hidden="true"></i>{{ $t('authentication.registerWithGoogle') }}
              </button>
              <button class="btn btn-lg btn-block btn-pill btn-outline-primary btn-social" type="button"
                      @click.prevent="fbAuth">
                <i class="fa fa-facebook-square " aria-hidden="true"></i>{{ $t('authentication.registerWithFacebook') }}
              </button>
            </div>


            <form @submit.prevent="registrationSubmit">
              <div class="mb-4">
                <div class="btn-group btn-group-lg w-100 " style="overflow: hidden">
                  <button class="btn btn-lg rounded-pill rounded-end" :class="{
                                    'btn-outline-primary': userType!== 'USER',
                                    'btn-primary': userType=== 'USER',
                                }" @click.prevent="userType='USER'"> {{ $t('userType.user') }}
                  </button>
                  <button class="btn btn-lg  rounded-pill rounded-start" :class="{
                                    'btn-outline-primary': userType!== 'EXPERT',
                                    'btn-primary': userType=== 'EXPERT',
                                }" @click.prevent="userType='EXPERT'"> {{ $t('userType.expert') }}
                  </button>
                </div>
              </div>
              <div class="mb-4">
                <input class="form-control form-control-lg" type="email" :placeholder="$t('authentication.email')"
                       v-model="email" autocomplete="email" required>
              </div>
              <div class="mb-4">
                <input class="form-control form-control-lg" type="text" :placeholder="$t('authentication.name')"
                       v-model="name"   maxlength="20" title="Only letters are allowed"
                       autocomplete="given-name" required>
              </div>
              <div class="mb-4">
                <input class="form-control form-control-lg" type="text" :placeholder="$t('authentication.surname')"
                       v-model="surname"  maxlength="20" title="Only letters are allowed"
                       autocomplete="family-name" required>
              </div>
              <div class="input-group input-group-lg mb-4">
                <input class="form-control form-control-lg" :type="passwordVisible?'text':'password'"
                       :placeholder="$t('authentication.password')" v-model="password"
                       pattern="(.){8,30}" title="Minimum 8, maximum 30 symbols" required>
                <div class="input-group-text" @click="passwordVisible = !passwordVisible">
                  <i class="fa" aria-hidden="true"
                     :class="{'fa-eye':!passwordVisible, 'fa-eye-slash': passwordVisible}"></i>
                </div>
              </div>
              <div class="mb-4">
                <div class="input-group input-group-lg">
                  <input class="form-control form-control-lg"
                         :type="passwordRepeatVisible?'text':'password'"
                         :placeholder="$t('authentication.passwordRepeat')" v-model="passwordRepeat"
                         pattern="(.){8,30}" title="Minimum 8, maximum 30 symbols" required>
                  <div class="input-group-text"
                       @click="passwordRepeatVisible = !passwordRepeatVisible">
                    <i class="fa" aria-hidden="true"
                       :class="{'fa-eye':!passwordRepeatVisible, 'fa-eye-slash': passwordRepeatVisible}"></i>
                  </div>
                </div>
                <div class="text-danger small m-2"
                     v-if="passwordRepeat !== '' && passwordRepeat !== password">
                  {{ $t('authentication.passwordsNotMatch') }}
                </div>
              </div>


              <div class="mb-4 form-check">
                <input class="form-check-input" type="checkbox" id="terms" required>
                <label class="form-check-label" for="terms">
                  <a target="_blank" href="/page/terms">
                    {{ $t('general.acceptTermsAndConditions') }}
                  </a>
                </label>
              </div>

              <div class="my-3 alert alert-danger" v-if="registrationError!==''">
                {{ registrationError }}
              </div>


              <div class="mb-4 " v-if="registrationIsLoading">
                {{ $t('general.loading') }}
              </div>
              <div class="mb-4 " v-else>
                <button class="btn btn-primary btn-lg btn-pill px-4">
                  {{ $t('authentication.complete') }}
                </button>
              </div>
            </form>
          </div>
        </div>

        <div class="my-5" v-if="registrationStep ===2">


          <h2 class="mb-4">{{ $t('authentication.enterOtpFromEmail') }}</h2>


          <form @submit.prevent="registrationOtpSubmit">
            <div class="mb-4">
              <input class="form-control form-control-lg" type="email"
                     v-model="email" autocomplete="email" readonly>
            </div>

            <div class="mb-4">
              <input class="form-control form-control-lg" type="number" :placeholder="$t('authentication.otp')"
                     v-model="otp" autocomplete="off" required>
            </div>

            <div class="my-3 alert alert-danger" v-if="otpError!==''">
              {{ otpError }}
            </div>


            <div class="mb-4 " v-if="otpIsLoading">
              {{ $t('general.loading') }}
            </div>
            <div class="mb-4 " v-else>
              <button class="btn btn-primary btn-lg btn-pill px-4">
                {{ $t('authentication.complete') }}
              </button>
            </div>
          </form>
        </div>

      </div>
      <div class="col-md-6 offset-1 mb-5 p-5 d-none d-lg-block">
        <img src="../assets/theme/registration.png" class="img-fluid" alt="image">
      </div>

    </div>

  </div>

  <the-footer/>

</template>

<script>
// @ is an alias to /src

import TopNavigation from "../components/layout/TopNavigation";
import TheFooter from "../components/layout/TheFooter";
import {register, registerOtp, withFacebook, withGoogle} from "@/repositories/GeneralDataRepository";
import customAlert from "../repositories/Utils";


export default {
  name: 'Registration',
  components: {TheFooter, TopNavigation},
  data() {
    return {

      passwordVisible: false,
      passwordRepeatVisible: false,
      email: '',
      name: '',
      surname: '',
      userType: 'USER',
      password: '',
      passwordRepeat: '',
      registrationError: '',
      registrationKey: '',
      registrationStep: 1,
      otp: '',
      otpError: '',

      registrationIsLoading: false,
      otpIsLoading: false,
      googleRegisterCase: false,
      googleIdToken: '',
      facebookAccessToken: '',
      facebookRegisterCase: false,
    };
  },
  methods: {
    registrationSubmit() {

      if (this.password === this.passwordRepeat) {

        this.registrationError = '';
        this.registrationIsLoading = true;

        register(this.email, this.password, this.name, this.surname, this.userType).then(res => {

          this.registrationIsLoading = false;
          if (res === null) {
            this.registrationError = this.$t('general.somethingWentWrong');
          } else {
            let {code, message, body} = res;

            if (code === 200) {

              this.registrationKey = body;
              this.registrationStep = 2;


            } else {
              this.registrationError = `${code}: ${message}`;
            }
          }

        })
      }

    },
    registrationOtpSubmit() {
      this.otpIsLoading = true;
      registerOtp(this.registrationKey, this.otp).then(res => {

        this.otpIsLoading = false;

        if (res === null) {
          this.otpError = this.$t('general.somethingWentWrong');
        } else {
          let {code, message, body} = res;

          if (code === 200) {

            localStorage.setItem('token', body.token);
            localStorage.setItem('userId', body.userId);

            this.$store.dispatch('auth/setToken', body.token);
            this.$store.dispatch('auth/loadUser', body.token);
            this.$router.push('/profile');

          } else if (code === 401) {
            this.otpError = this.$t('authentication.wrongPassword');
          } else {
            this.otpError = `${code}: ${message}`;
          }
        }

      });
    },
    loginWithGoogle(userType) {
      withGoogle(this.googleIdToken, userType).then((res) => {
        let {code, message, body} = res;
        if (code === 200) {
          localStorage.setItem('token', body.token);
          localStorage.setItem('userId', body.userId);

          this.$store.dispatch('auth/setToken', body.token);
          this.$store.dispatch('auth/loadUser', body.token);

          this.$router.push('/profile');

        } else if (code === 404) {

          this.googleRegisterCase = true;

        } else {
          customAlert(message, '', 'danger');
        }


      })
    },
    googleAuth() {
      this.$gAuth.signIn((user) => {


        this.googleIdToken =
            this.googleIdToken = user.getAuthResponse().id_token;
        this.loginWithGoogle('');

      }, (error) => {
        console.log(error);
        if (error === false) {
          customAlert(this.$t('authentication.googleNotInitialised'), '', 'danger');
        } else {
          customAlert(error.error, '', 'danger');
        }
      })
    },
    loginWithFacebook(userType) {
      console.log("login with facebook");
      withFacebook(this.facebookAccessToken, userType).then((res) => {
        let {code, message, body} = res;
        if (code === 200) {
          localStorage.setItem('token', body.token);
          localStorage.setItem('userId', body.userId);

          this.$store.dispatch('auth/setToken', body.token);
          this.$store.dispatch('auth/loadUser', body.token);

          this.$router.push('/profile');

        } else if (code === 404) {

          this.facebookRegisterCase = true;

        } else if (code === 403) {

          customAlert(this.$t('authentication.facebookLoginError'), this.$t('authentication.facebookEmailPermissionIsRequired'), 'danger');

        } else {
          customAlert(message, '', 'danger');
        }


      })
    },

    async fbAuth() {

      window.FB.login(({authResponse}) => {
        if (!authResponse) return;

        this.facebookAccessToken = authResponse.accessToken;
        this.loginWithFacebook('');
      }, {
        scope: 'public_profile,email',
        return_scopes: true,
        authType: 'reauthenticate',
        auth_nonce: (new Date()).getTime()
      });

    },


    googleRegistrationSubmit() {
      this.loginWithGoogle(this.userType);
    },

    facebookRegistrationSubmit() {
      this.loginWithFacebook(this.userType);
    }
  },
  watch: {
    "this.$route"() {
      if (this.$route.query['type']) {
        this.userType = this.$route.query['type'];
      }
    },
    name: function (val) {
      if(val && val.length>1) {
        this.name = val[0].toUpperCase() + val.slice(1).toLowerCase();
      }
    },
    surname: function (val) {
      if(val && val.length>1) {
        this.surname = val[0].toUpperCase() + val.slice(1).toLowerCase();
      }
    },
  },
  mounted() {

    console.log(this.$route);

    if (this.$route.query['type']) {
      this.userType = this.$route.query['type'];
    }


  }
}
</script>

<style lang="scss" scoped>

@import "../assets/colors.scss";


.btn-social {
  background: white;
  text-align: left;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    background: $color-violet;
  }
}


</style>
